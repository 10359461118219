<template>
    <div>
        <!--   第一单元     -->
        <div v-if="Number(type) === 1" class="content tc">
            <img class="banner" src="@/assets/imgs/B1.webp">
            <img class="con" src="@/assets/imgs/B2.webp">
        </div>
        <!--   第二单元     -->
        <div v-else-if="Number(type) === 2" class="content content-bg2 tc">
            <img class="banner" src="@/assets/imgs/C1.webp">
            <img class="con" src="@/assets/imgs/C2.webp">
        </div>
        <!--   第三单元     -->
        <div v-else-if="Number(type) === 3" class="content  content-bg3 tc">
            <img class="banner" src="@/assets/imgs/D1.webp">
            <div class="pr">
                <img class="con" :src="require(`@/assets/imgs/D2-${selectIdx+1}.webp`)">
                <!--    菜单        -->
                <ul class="pa">
                    <li v-for="(item,i) in lists" :class="selectIdx === i && 'checked'" :key="i" @click="handleSwitch(i)">
                        <i>第{{item.sort}}部分</i>
                        <p>{{item.name}}</p>
                    </li>
                </ul>
            </div>
        </div>

        <!--   第四单元     -->
        <div v-else-if="Number(type) === 4" class="content content-bg4 tc">
            <img class="banner" src="@/assets/imgs/F1.webp">
            <img class="con" src="@/assets/imgs/F2.webp">
            <div class="con-bg4 tc">
                <img class="con" src="@/assets/imgs/F3.webp">
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref} from "vue";
    import { useRoute, useRouter } from 'vue-router/composables'
    const route = useRoute()

    const lists = [
        {name:'物阜民丰',sort:'一'},
        {name:'繁华富庶',sort:'二'},
        {name:'文化勃兴',sort:'三'},
    ]
    let selectIdx=ref(0)
    const handleSwitch=(i)=>{
        selectIdx.value = i
    }

    const {type =1} = route.query


</script>

<style lang="scss" scoped>
.content{
    background:#d6d6d6;
    font-size: 0;
    .banner{
        width: 100%;
    }
    .con{
        max-width: 1175px;
        margin-top: 89px;
        margin-bottom: 174px;
    }

    &.content-bg2{
        background:#c9c4b5;
    }
    &.content-bg3{
        background:#e5e1d9;
    }
    &.content-bg4{
        background:#c4b1b1;
    }
    .con-bg4{
        background:#54545a;
    }

    ul{
        top: 116px;
        left: 0;
        li{
            width:304px;
            height:81px;
            text-align: center;
            position: relative;
            margin-bottom: 8px;
            background:#373737;
            cursor: pointer;

            &.checked{
                background:#8b3527;
            }

            &:hover{
                background:#acacac;

                p{
                    color:#8b3527;
                }
                i{
                    border-color:#8b3527;
                    color: #8b3527;
                }
            }

            p{
                font-weight:700;
                color:#d9c7ac;
                font-size:43px;
                line-height: 81px;
                letter-spacing: 4px;
            }
            i{
                display: block;
                width:20px;
                height:64px;
                border:1px solid #d9c7ac;
                font-style: normal;
                position: absolute;
                left: 10px;
                top: 9px;
                color: #d9c7ac;
                font-size: 12px;
                //line-height: 1;
            }
        }
    }
}
</style>
